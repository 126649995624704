import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import calendar from './components/calendar'
import Color from './components/color'
import slider from './components/slider'
import html2canvas from 'html2canvas'
import cssart from './components/cssart'


window.Alpine = Alpine
window.Color = Color
window.html2canvas = html2canvas
Alpine.data('calendar', calendar)
Alpine.data('cssart', cssart)
Alpine.plugin(intersect)
Alpine.start()