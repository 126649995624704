import Style from './base';
import StyleWithUnit from './withUnit';

class StyleAnchor extends StyleWithUnit {
    constructor(args) {
        super(args);
        this.the_class = 'anchorModelWithUnit';
        this.multiValueSeparator = /\s+/;
        this.defaultUnit = 'px';
        this._cycleIndex = this.cycleIndex;
        this.underlyingPropertyKeys = ['x', 'y', 'z'];
    }
    get cycle() {
        if (this._cycle) {
            return this._cycle;
        }
        this._cycleKeys = ['x', 'y'];
        const x = ['0%', '50%', '100%'];
        const y = ['0%', '50%', '100%'];
        const cycle = [
            { x: x[0], y: y[0] },
            { x: x[1], y: y[0] },
            { x: x[2], y: y[0] },
            { x: x[2], y: y[1] },
            { x: x[2], y: y[2] },
            { x: x[1], y: y[2] },
            { x: x[0], y: y[2] },
            { x: x[0], y: y[1] },
            { x: x[1], y: y[1] },
        ];
        this._cycle = cycle;
        return this._cycle;
    }
    get cycleIndex() {
        if (!this.cycle) {
            return {};
        }
        if (this._cycleIndex) {
            return this._cycleIndex;
        }
            const cycleIndex = {};
            for (let i = 0; i < this.cycle.length; i++) {
                const item = this.cycle[i];
                let currentLevel = cycleIndex;
                for (let j = 0; j < this._cycleKeys.length; j++) {
                    const propName = this._cycleKeys[j];
                    if (j === this._cycleKeys.length - 1) {
                        currentLevel[item[propName]] = i;
                    } else {
                        if (!currentLevel[item[propName]]) {
                            currentLevel[item[propName]] = {};
                        }
                        currentLevel = currentLevel[item[propName]];
                    }
                }
            }
        this._cycleIndex = cycleIndex;
        return this._cycleIndex;
    }
    get cycleInput() {
        const params = {x: this.styles[`${this.getLonghandPrefix()}x`].css, y: this.styles[`${this.getLonghandPrefix()}y`].css};
        return params;
    }
    get cycleCurrentIndex() {
        const index = this.cycleIndex?.[this.cycleInput.x]?.[this.cycleInput.y];
        return index ?? 0;
    }
    get cycleCurrent() {
        return this.cycle[this.cycleCurrentIndex];
    }
    get cycleNext() {
        return this.cycle[(this.cycleCurrentIndex + 1) % this.cycle.length];
    }
    get cyclePrevious() {
        return this.cycle[(this.cycleCurrentIndex - 1 + this.cycle.length) % this.cycle.length];
    }
    getCurrentIndex(pair) {
        return this.cycle.get(JSON.stringify(pair));
    }
    get css() {
        // 
        return this.packAnchorModel()
    }
    increment(value) {
        this._value.value = this._value.value + value;
    }
    decrement(value) {
        this._value.value = this._value.value - value;
    }
    parseAnchorModel(value) {
        let values = value.split(this.multiValueSeparator);
        let x = values[0];
        let y = values[1] || '50%'; // Default y is 50% if not provided
        let z = values[2] || null; // Default z is 0 if not provided
        // console.log('Anchor parseAnchor', value, values, this.multiValueSeparator, { x, y, z });
        return { x, y, z: z ?? undefined };
    }
    getLonghandPrefix() {
        return 'transform-origin-'
    }
    packAnchorModel() {
        const x = this.styles[`${this.getLonghandPrefix()}x`].css;
        const y = this.styles[`${this.getLonghandPrefix()}y`].css;
        const z = this.styles[`${this.getLonghandPrefix()}z`].css;
    
        // If all values are default (50% 50% 0), return an empty string or appropriate default.
        // Return only the necessary number of values
        if (z && z !== '0') {
            return `${x} ${y} ${z}`;
        } else {
            return `${x} ${y}`;
        }
    }
    increment(value) {
        // console.log('TTTTTTTanchor incrementing cycle', { [`${this.getLonghandPrefix()}x`]: this.styles[`${this.getLonghandPrefix()}x`] ?? 'no X', [`${this.getLonghandPrefix()}y`]: this.styles[`${this.getLonghandPrefix()}y`] ?? 'no y', cycle: this.cycle, cycleCurrentIndex: this.cycleCurrent, cycleCurrentIndex: this.cycleCurrentIndex });
        this.setUnderlying(this.cycleNext);
    }
    decrement(value) {
        this.setUnderlying(this.cyclePrevious);
    }
    setUnderlying(properties){
        for (const subKey in properties) {
            // console.log('YYYYYYYYYYYYYYY setting new style', this.styles[`${this.getLonghandPrefix()}${subKey}`].constructor.name,{default: this.styles[`${this.getLonghandPrefix()}${subKey}`].getDefaultValue()}, this.styles[`${this.getLonghandPrefix()}${subKey}`], `${this.getLonghandPrefix()}${subKey}`, properties[subKey]);
            this.styles[`${this.getLonghandPrefix()}${subKey}`] = properties[subKey];
        }
    }
    set value(inputValue) {
        if (this._value === undefined) {
            this._value = {};
        }
        // verbose detailed console log
        
        if (typeof inputValue === 'string') {
            const anchorModelValues = this.parseAnchorModel(inputValue);
            
            this._value = {};
            this.setUnderlying(anchorModelValues);
        } else if (typeof inputValue === 'object' && inputValue != null) {
            // console.log('StyleWithUnit INTPUTVALUE zzzzzzzzz', inputValue);
            if (inputValue.value !== undefined) {
                this._value = inputValue.value;
                if (inputValue.unit !== undefined) {
                    this._value.unit = inputValue.unit;
                }
            }
        } else {
            this._value.value = inputValue;
            this._value.unit = null;
        }
    }
}

export default StyleAnchor;