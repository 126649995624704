// artBoard.js
import Visual from './visual.js';
import html2canvas from 'html2canvas'

class ArtBoard {
    constructor({canvas = null, blob, id = null, visuals = [], initial_fill = null, initial_color = null, ...args} = {}) {
        if (!canvas) {
            throw new Error('ArtBoard must be initialized with a canvas.');
        }
        // We do not want blob to be ableto be passed in.
        this.the_class = 'ArtBoard';
        this.canvas = canvas;
        // console.log('Adding art boardPQQQQQQQQQQQQQ', {id, visuals, initial_fill, initial_color, ...args});
        this.id = id ?? Math.random().toString(36).substring(7);
        this.visuals = [];
        this.initial_fill = initial_fill;
        this.initial_color = initial_color;
        this.selected = false; // Initialize with unselected state
        Object.assign(this, args);
        visuals.forEach(visual => this.addVisual(visual));
        this.excludeFromJSON = ['the_class', 'canvas', 'internal_id', 'excludeFromJSON', 'aspectFraction'];
        if (!this.visuals.find(visual => visual.type === 'bg')) {
            this.addVisual({
                id: 'bg',
                type: 'bg',
                svg: '<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"><rect width="100%" height="100%" /></svg>',
                styles: {
                    fill: this.initial_fill,
                    color: this.initial_color,
                    inset: '0px',
                }
            }, 0);
        }
        return new Proxy(this, {
            get(target, prop, receiver) {
                // Handle method calls ending with 'OnVisuals'
                if (Reflect.has(target, prop)) {
                    return Reflect.get(target, prop, receiver);
                }
                // console.log('4444444444444444444 prop',prop);
                let onVisuals, onVisual, methodName;
                if (typeof prop === 'string') {
                    onVisuals = prop.endsWith('__onSelectedVisuals');
                    if (onVisuals) {
                        methodName = prop.replace('__onSelectedVisuals', '');
                    } else {
                        onVisual = prop.endsWith('__onSelectedVisual1');
                        if (onVisual) methodName = prop.replace('__onSelectedVisual1', '');
                    }
                }
                if (methodName) {
                    return (...args) => {
                        // console.log('777777777777777 main prop',prop, {methodName, onVisuals, onVisual}, receiver.getSelected());
                        const result = receiver.getSelected().map(visual => {
                            if (typeof visual[methodName] === 'function') {
                                return visual[methodName].call(visual, ...args);
                            } else if (Reflect.has(visual, methodName)){
                                // console.warn(`${methodName} is a property on Visual.`);
                                return Reflect.get(visual, methodName, visual);
                            } else {
                                console.warn(`Method ${methodName} not found on Visual.`);
                            }
                        }).filter(result => result !== undefined);
                        return onVisual ? result[0] : result;
                    };
                }
                // Fallback to normal property/method
                return Reflect.get(target, prop, receiver);
            }
        });
    }
    async uploadPostFile() {
        // await new Promise(resolve => setTimeout(resolve, 2000));
        if (!this.blob || this.blob instanceof Blob === false) {
            console.warn('No blob to upload');
            return;
        }
        const formData = new FormData();
        formData.append('file', this.blob, `post-${this.canvas.app.post_slug}-${this.name.replace(/[^A-Za-z0-9_-]+/ig,'_').replace(/_+/g,'_')}.jpg`);
        formData.append('name', `Post: ${this.canvas.app.post_name}`);
        formData.append('template', 'post');
        console.log("FORMDATA",JSON.stringify(formData,null,2));
        // This end creates or replaces.
        try {
        const response = await fetch("/api/pages/files+images+posts/files_vv", {
            method: "POST",
            headers: {
                "X-CSRF": csrf
            },
            body: formData
        });
        const json = await response.json();
        console.log('uploadPostFile response', json);
        this.uploadedFileId = json.data.id;
        console.log('uploadPostFile uploadedFileId', this.uploadedFileId);
        } catch (error) {
            console.error('Error uploading post file:', error);
        }
    }
    set uploadedFileId(id) {
        if (id && id !== this._uploadedFileId) {
            this._uploadedFileId = id;
            // this.canvas.app.updatePost();
        }
    }
    get uploadedFileId() {
        return this._uploadedFileId;
    }
    async convertToImage() {
        console.warn('wehave this canvas while convertgin to image', this.canvas, this);
        const element = this.canvas?.app?.rootElement?.querySelector(`.BOARD#board_${this.id} .graphic`);
        const fontFace = await this.canvas.getFont();
        element.querySelectorAll('svg').forEach(target => {

            const styleEl = document.createElement("style")
            styleEl.appendChild(document.createTextNode(fontFace))
            target.appendChild(styleEl)

        });
        document.documentElement.style.overflow = 'hidden';
        console.log('html2canvas', element);
        try {
            // Wait for html2canvas to resolve and assign the resolved value to this.blob
            const htmlcanvas = await html2canvas(element, {
                allowTaint: true,
                scale: 5,
                removeContainer: true,
            });
            const blob = await new Promise(resolve => htmlcanvas.toBlob(resolve, 'image/jpeg', .9));
            console.log('html2canvas blob', blob);
            this.blob = blob;
            await this.uploadPostFile();
        } catch (error) {
            // Handle errors in the try block
            console.error('html2canvas Error:', error);
        }
        document.documentElement.style.overflow = '';
        // console log before and after doing and uploa dand do the upload await.
        console.log('Now uploading the post file', this.name);
        console.log('Done uploading the post file', this.name);
    }
    get name() {
        return this._name ?? (this.type ? this.type + ' ' : '')+ this.id;
    }
    set name(name) {
        this._name = name || null;
    }
    get type() {
        return this._type ?? 'default';
    }
    set type(type) {
        this._type = type;
    }
    get aspect() {
        return this._aspect ?? '4:5';
    }
    get aspectFraction() {
        let aspect = this.aspect;
        let [width, height] = aspect.split(':').map(Number);

        // Check if width and height are valid numbers
        if (isNaN(width) || isNaN(height) || height == null || height === 0) {
            if (!isNaN(width)) {
                height = width;
            } else {
                width = 4;
                height = 5;
            }
        }
        return width / height;
    }
    set aspect(aspect) {
        this._aspect = aspect;
    }
    getAll() {
        return this.visuals;
    }
    getSelectedOrFirst() {
        return this.getSelected().length ? this.getSelected() : [this.visuals[0]];
    }
    get visuals() {
        return this._visuals ?? [];
    }
    set visuals(visuals) {
        this._visuals = visuals;
    }
    addVisual(visual = {}, visual_index = null) {
        visual.internal_id = visual.internal_id ?? 'id_' + this.visuals.length + '_' + (Math.random().toString(36)).substring(7);
        const the_visual = new Visual({artboard: this, ...visual});
        this.visuals.splice((visual_index === 0 || visual_index) ? visual_index + 1 : this.visuals.length, 0, the_visual);
        return the_visual;
    }

    moveVisual(visual, direction) {
        const index = this.visuals.findIndex(i => i.id === visual.id);
        if (visual.type === 'bg' || (direction === -1 && index <= 1) || (direction === 1 && index === this.visuals.length - 1)) {
            console.warn('Cannot move visual:', visual.id, direction, index);
            return;
        }
        [this.visuals[index], this.visuals[index + direction]] = [this.visuals[index + direction], this.visuals[index]];
    }
    get selected() {
        return this._selected || this.getSelected().length > 0;
    }
    set selected(selected) {
        this._selected = selected;
    }
    deleteVisual() {
        let nextSelectedIndex = null;

        for (let i = this.visuals.length - 1; i >= 0; i--) {
            if (this.visuals[i].selected) {
                nextSelectedIndex = i + 1; // Select the next visual up
                this.visuals.splice(i, 1);
            }
        }

        // If the next visual up is not available, select the next visual down
        if (nextSelectedIndex >= this.visuals.length) {
            nextSelectedIndex = this.visuals.length - 1;
        }

        // If there are no visuals left, nextSelectedIndex will be -1
        if (nextSelectedIndex >= 0) {
            this.selectVisual(this.visuals[nextSelectedIndex]);
        }
    }

    selectVisual(visual) {
        visual.selected = true;
    }

    unselectVisual(visual) {
        visual.selected = false;
    }
    selectAll() {
        this.visuals.forEach(visual => visual.selected = true);
    }
    getSelected() {
        return this.visuals.filter(v => v.selected);
    }

    getFirstSelectedVisual() {
        return (this.getSelected().length >= 1) ? this.getSelected()[0] : {};
    }

    clearAll(exceptForVisual = null) {
        this.getSelected().forEach(visual => {
            if (!exceptForVisual || exceptForVisual.id !== visual.id) {
                visual.selected = false;
            }
        });
    }
    getVisualByID(id) {
        return this.visuals.find(v => v.id === id);
    }
    getVisualByInternalID(id) {
        return this.visuals.find(v => v.id === id);
    }
    getVisualByIndex(index) {
        // console.log("AAAAAAAAArtboard inside getvisualbyindex", this);
        return this.visuals[index];
    }
    toJSON() {
        // returns also getter props instead of their underlying "_" props
        const descriptors = Object.getOwnPropertyDescriptors(Object.getPrototypeOf(this));
        const getterProps = Object.keys(descriptors).reduce((acc, key) => {
            if (typeof descriptors[key].get === 'function' && !this.excludeFromJSON.includes(key)) {
                acc[key] = this[key];
            }
            return acc;
        }, {});
        // console.log('ZZZZZZZZZZZZZZZZZZZZZZ88', getterProps,Object.entries(this));
        return {
            ...getterProps,
            ...Object.fromEntries(Object.entries(this).filter(([key]) => !this.excludeFromJSON.includes(key) && (!key.startsWith('_') || !(key.slice(1) in getterProps)) )),
            // ...this,
        };
    }
}

export default ArtBoard;
