import Style from './style/base';
import StyleColor from './style/color';
import StyleTransform from './style/transform';
import StyleWithUnit from './style/withUnit';
import StyleShorthandBoxModelWithUnit from './style/box';
import StyleAnchor from './style/anchor';

const StyleFill = StyleColor;
const StyleStroke = StyleColor;
const StyleLeft = StyleWithUnit;
const StyleRight = StyleWithUnit;
const StyleTop = StyleWithUnit;
const StyleBottom = StyleWithUnit;
const StyleInset = StyleShorthandBoxModelWithUnit;
const StyleTransformOrigin = StyleAnchor;
export {
    Style,
    StyleWithUnit,
    StyleShorthandBoxModelWithUnit,
    StyleAnchor,
    StyleLeft,
    StyleRight,
    StyleTop,
    StyleBottom,
    StyleInset,
    StyleTransformOrigin,
    StyleTransform,
};