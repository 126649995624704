export default class Color {
    _selectedColors = [];
    _selectedColors1 = [{
        "destination_key": "main3",
        "color": {
            "color": "#F94C66",
            "colorful": "true",
            "id": "29",
            "name": "salmon",
            "strength": "light",
            "theme": "false",
            "group": [
                "pink",
                "beige",
                "",
                "brown",
                "green",
                "beige"
            ]
        }
    }
    ];
    _selectedColors2 = [
        {
            "destination_key": "test",
            "color": {
                "color": "#FF3C00"
            }
        },
        {
            "destination_key": "test1",
            "color": {
                "color": "#F72585"
            }
        },
        {
            "destination_key": "test2",
            "color": {
                "color": "#ff476f",
                "group": [
                    "purple",
                    "",
                    "beige"
                ]
            }
        },
        {
            "destination_key": "test3",
            "color": {
                "color": "#FF5D8F"
            }
        },
        {
            "destination_key": "test4",
            "color": {
                "color": "#FF4B91"
            }
        },
        {
            "destination_key": "test5",
            "color": {
                "color": "#e91e63"
            }
        },
        {
            "destination_key": "test6",
            "color": {
                "color": "#FF0060"
            }
        },
        {
            "destination_key": "main2",
            "color": {
                "color": "#fcacb0",
                "colorful": "true",
                "id": "32",
                "name": "dirtylightpink",
                "strength": "light",
                "theme": "true",
                "group": [
                    "yellow"
                ]
            }
        },
        {
            "destination_key": "main3",
            "color": {
                "color": "#F94C66",
                "colorful": "true",
                "id": "29",
                "name": "salmon",
                "strength": "light",
                "theme": "false",
                "group": [
                    "pink",
                    "beige",
                    "",
                    "brown",
                    "green",
                    "beige"
                ]
            }
        }
    ];
    MAX_TRIES_FOR_LOOP = 10;
    constructor(colors = []) {
        if (!Array.isArray(colors)) {
            throw new Error('Invalid argument: colors must be an array');
        }
        this._colors = colors.map(({ group, ...rest }) => ({
            ...rest,
            group: group.split(',').map(group => group.trim())
        }));
    }

    colors() {
        return this._colors;
    }

    /**
     * This function is used to get a subset of the _selectedColors array based on the exclusionLookbackBasis parameter.
     * 
     * @param {number|array|null} exclusionLookbackBasis - This parameter can be a number, an array, or null. 
     * If it's a number, it represents the number of elements to consider from the _selectedColors array. 
     * If it's an array, it contains the destination_keys of the colors to consider. 
     * If it's null, the function will consider all colors in the _selectedColors array.
     * 
     * @returns {array} - The function returns a subset of the _selectedColors array.
     * 
     * If exclusionLookbackBasis is a positive integer, it returns the last exclusionLookbackBasis elements from _selectedColors.
     * If exclusionLookbackBasis is a negative integer, it returns the first exclusionLookbackBasis elements from _selectedColors.
     * If exclusionLookbackBasis is zero, it returns an empty array.
     * 
     * If exclusionLookbackBasis is an array, it returns the colors from _selectedColors whose destination_key is included in the exclusionLookbackBasis array.
     * 
     * If exclusionLookbackBasis is null, it returns the entire _selectedColors array.
     */
    getFilterBasis(exclusionLookbackBasis = null) {
        console.log('exclusionLookbackBasis', exclusionLookbackBasis);
        if (Number.isInteger(exclusionLookbackBasis)) {
            return exclusionLookbackBasis !== 0 ?
                (exclusionLookbackBasis < 0 ?
                    this._selectedColors.slice(0, -exclusionLookbackBasis)
                    :
                    this._selectedColors.slice(-exclusionLookbackBasis))
                : [];
        } else if (Array.isArray(exclusionLookbackBasis) && exclusionLookbackBasis.length > 0) {
            return this._selectedColors.filter(color => exclusionLookbackBasis.includes(color.destination_key));
        } else {
            return this._selectedColors;
        }
    }
    getFilterCallback(filterKeysAndValues = null, exclude = false) {
        if (!filterKeysAndValues) {
            return () => true;
        }
        // This is a callback function for filtering items based on certain criteria.
        // It takes an item as an argument and checks if all the keys in the filterKeysAndValues object are present in the item.
        // For each key, it checks if the corresponding value in the item matches any of the values in the filterKeysAndValues object.
        // If the value in the filterKeysAndValues object is not an array, it is converted into an array for comparison.
        // Similarly, if the value in the item is not an array, it is converted into an array for comparison.
        // The function returns true if all keys in the filterKeysAndValues object have a matching value in the item, and false otherwise.
        return (item) => {
            return Object.keys(filterKeysAndValues).every(key => {
                const filterValues = Array.isArray(filterKeysAndValues[key]) ? filterKeysAndValues[key] : [filterKeysAndValues[key]];
                const itemValues = Array.isArray(item[key]) ? item[key] : [item[key]];
                return exclude ? !filterValues.some(filterValue => itemValues.includes(filterValue)) : filterValues.some(filterValue => itemValues.includes(filterValue));
            });
        };
    }
    getExclusionFilterCallback(exclusionLookbackBasis, exclusionFilterKeys = ['group', 'color']) {
        // let's construct the filterKeysAndValues object from the exclusionLookbackBasis and exclusionFilterKeys
        // exclusionLookbackBasis = 0 disables, exclusionLookbackBasis = null/undefined uses all
        const filterBasis = this.getFilterBasis(exclusionLookbackBasis);
        const filterKeysAndValues = exclusionFilterKeys.reduce((acc, key) => {
            acc[key] = filterBasis.flatMap(obj => obj.color[key]);
            return acc;
        }, {});
        return this.getFilterCallback(filterKeysAndValues, true);

    }
    // exclusionLookbackBasis = [] disables, exclusionLookbackBasis = null/undefined uses all

    /**
     * Retrieves a color for a given destination key, with optional filtering and exclusion criteria.
     * 
     * @param {string|null} destinationKey - The destination key for which to retrieve the color. If provided, the function will check if the color has already been selected and return it. If not provided, a new color will be selected.
     * @param {boolean} redo - Specifies whether to select a new color even if the destination key has already been selected before. If set to true, the function will remove the previously selected color for the destination key and select a new one.
     * @param {object} inclusionFilterKeysAndValues - An object containing key-value pairs to filter the color choices. Only colors that match all the specified key-value pairs will be considered.
     * @param {number|null} index - The index of the color to select from the filtered color choices. If not provided, a random color will be selected.
     * @param {number|array|null} exclusionLookbackBasis - The basis for excluding colors from the selection. If it's a number, it represents the number of previously selected colors to exclude. If it's an array, it contains the destination keys of the colors to exclude. If it's null, no colors will be excluded based on previous selections.
     * @param {array} exclusionFilterKeys - An array of keys to use for excluding colors. Only colors that have different values for all the specified keys compared to the previously selected colors will be considered.
     * 
     * @returns {object} - The selected color.
     */
    getColorFor(destinationKey = null, redo = false, inclusionFilterKeysAndValues, index = null, exclusionLookbackBasis = null, exclusionFilterKeys) {
        if (destinationKey) {
            destinationKey = destinationKey.toLowerCase();
            const selectedColor = this._selectedColors.find(selectedColor => selectedColor.destination_key === destinationKey)
            if (selectedColor) {
                if (!redo) {
                    return selectedColor.color;
                } else {
                    this._selectedColors = this._selectedColors.filter(selectedColor => selectedColor.destination_key !== destinationKey);
                }
            }
        }
        let colorChoices, newColor;
        /*
        if exclusionLookbackBasis is null, inthe next iteration then set it to the length
        of _selectedColors minus 1, then each time reduce by one until you get a newColor.
        if it is a positive integer, in the next iteration reduce it by one.
        if it is a negative integer, in the next iteration increase it by one.
        if it is an array, in the next iteration remove the first element.
        */
        let triesLeft = this.MAX_TRIES_FOR_LOOP;
        do {
            let filterCallbackExclude = this.getExclusionFilterCallback(exclusionLookbackBasis, exclusionFilterKeys);
            let filterCallbackInclude = this.getFilterCallback(inclusionFilterKeysAndValues);
            let filterCallback = (item) => filterCallbackInclude(item) && filterCallbackExclude(item);
            colorChoices = this.themeColors(filterCallback);

            console.log('trying with exclusionLookbackBasis', exclusionLookbackBasis);
            // Modify exclusionLookbackBasis for the next iteration
            if (!colorChoices || colorChoices.length === 0) {
                if (exclusionLookbackBasis === null) {
                    exclusionLookbackBasis = this._selectedColors.length - 1;
                } else if (Number.isInteger(exclusionLookbackBasis)) {
                    exclusionLookbackBasis += exclusionLookbackBasis > 0 ? -1 : 1;
                } else if (Array.isArray(exclusionLookbackBasis)) {
                    exclusionLookbackBasis.shift();
                }
            }
        } while ((!colorChoices || colorChoices.length === 0) && triesLeft-- > 0);
        if (!colorChoices) {
            colorChoices = this.themeColors();
            console.log('No color found, using random color', colorChoices);
        }
        newColor = colorChoices[index === null ? (Math.floor(Math.random() * colorChoices.length)) : (index % colorChoices.length)];
        if (destinationKey) {
            this._selectedColors.push({ destination_key: destinationKey, color: newColor });
        }
        return newColor;
    }

    randomThemeColor(filter, getKey, filterKey) {
        const colors = this.themeColors(filter, getKey, filterKey);
        return colors[Math.floor(Math.random() * colors.length)];
    }

    themeColor(filter, getKey, filterKey) {
        return this.themeColors(filter, getKey, filterKey)[0];
    }

    themeColors(filter, getKey = null, filterKey = 'color') {
        let $_choices;
        if (typeof filter === 'function') {
            $_choices = this._colors.filter(filter);
        } else if (filter && filterKey) {
            const filterCallback = item => item[filterKey] === filter;
            $_choices = this._colors.filter(filterCallback);
        } else {
            $_choices = this._colors;
        }

        if ($_choices.length > 0) {
            return getKey ? $_choices.map(color => color[getKey]) : $_choices;
        }

        return [];
    }
}