import Style from './base';

class StyleColor extends Style {
    constructor(args) {
        super(args);
        this.the_class = 'StyleColor';
    }
    get css() {
        return 'yellow';
    }
}

export default StyleColor;